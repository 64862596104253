import React from 'react';
import { blogLeft, blogRightBottom, blogRightTop, leaf, poster, services1, services2, services3 } from '../../../assets/images';

const Services = () => {
  return (
    <div className='mt-[40px] md:mt-[160px] md:pt-20'>
           <div className='mb-[40px] text-center'>
     <p className=' text-xl italic text-[#BCBCBC]'>Xidmətlər</p>
       <p className='text-[#6F9B0B] text-[22px] md:text-[32px] font-medium'>İstifadə sahəsi</p>
     </div>
    <div className='md:max-w-[1260px] max-w-containerSm md:h-[416px] mx-auto md:flex justify-between mt-[20px] md:mt-[100px] mb-[40px] md:mb-[100px]'>
    <div>
   <img className='md:h-[348px] md:w-[524px]' src={blogLeft}></img>
    </div> 
    <div className='flex flex-col justify-center md:w-[696px] mt-[40px] md:mt-0'>
      <p className='text-lg'>Parniklərdə, ştilxanalarda, quşçuluq və heyvandarlıqda, açıq və qapalı hovuzlarda, xəstəxanalarda, hamamlarda, suvarma sistemlərində, bütün növ məkanların dezinfeksiyasında bizim məhsulumuzu istifadə edə bilərsiniz. . Hər damlasında möcüzə var. Buna zəmanət veririk.</p>
      <a href='/services'> <p className='text-[#6F9B0B] mt-3'>Ətraflı bax <i class="fa-solid fa-arrow-right"></i></p></a>
    </div>
    </div>
  </div>
  );
};

export default Services;

