import React from 'react';
import Discount from '../../Components/home/Hero/Hero';
import Products from '../../Components/home/Products/Products';
import WhyUs from '../../Components/home/WhyUs/WhyUs';
import Services from '../../Components/home/Services/Services';
import Blogs from '../../Components/home/Blogs/Blogs';
import Contact from '../../Components/home/Contact/Contact';



const Home = () => {
  return (
    <> 
     <Discount/>
     <WhyUs/> 
    <Products/>
    <Services/>
    <Blogs/>
  <Contact/>

    
    </>
  )
}

export default Home;
