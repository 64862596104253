import React from 'react'



const ProductCard = ({ title, text, image1 }) => {

  console.log(image1)

  const truncatedTitle = title.length > 30 ? title.slice(0, 30) + "..." : title;
  const truncatedText = text.length > 100 ? text.slice(0, 100) + "..." : text;
  
  return (
    <div className=" bg-[white] rounded-lg overflow-hidden shadow-sm p-3">
        <div className='bg-[white] mx-auto rounded-md flex justify-center items-center '>
        <img  src={`https://www.sarkimya.az/sarkimya/${image1}`} alt={title} className=" object-cover mx-auto" />
        </div>
      <div className="pt-2.5 pb-4">
        <h3 className="text-[#6F9B0B] text-lg font-medium">{truncatedTitle}</h3>
        <h3 className="text-[black]">{truncatedText}</h3>
      </div>
    </div>
  )
}

export default ProductCard