import React from 'react'
import { whoUsBg, whyus } from '../../assets/images';

const WhoUs = () => {
  return (
   <>
   {/* Background image applied to the div */}
   <div style={{height: window.innerWidth < 640 ? '147px' : '310px', backgroundImage: `url(${whoUsBg})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent:'center', alignItems:'end' }}>
     <div className='mb-[16px] md:mb-[44px] text-center'>
       <p className='text-xl italic text-[#BCBCBC]'>Biz kimik?</p>
       <p className='text-[#6F9B0B] text-[22px] md:text-[32px] font-medium'>S.A.R Kimya</p>
     </div>
   </div>

   <div> 
     <div className='md:max-w-[1260px] max-w-containerSm mx-auto'>
       <div className='md:flex justify-between mt-[40px] md:mt-[100px] mb-[40px] md:mb-[160px]'>
         <div className='md:w-1/3'>
           <img className='h-[436px]' src={whyus} alt="Why Us"></img>
         </div>
         <div className='md:w-3/5 flex justify-center items-center mt-4 md:mt-0'>
           <p>
             Biz S.A.R Kimya olaraq yaşadığımız dünyanın təmiz, yediyimiz qidaların sağlıqlı və toxunduğumuz ətraf mühitin xəstəliksiz olması şüarı ilə yola çıxmış, işini sevən, məsuliyyətini dərk edən bir şirkətik.. S.A.R Kimya olaraq istixanalarda modern əkinçiliyin tələblərinə uyğun xidmət göstərir, bitki və məhusllarınızı xəstəliklərdən, zərərvericilərdən qoruyan, bitkilərin kök inkişafını dəstəkləyən və uzun müddət keyfiyyətini saxlayan, Belçikada, xüsusi mütəxəssis heyyəti tərəfindən istehsal edilmiş Huwa-San TR50-nin satışını həyata keçirir, bununla yanaşı sizə, 10 ildən çox təcrübəsi olan əməkdaşlarımızla agronom xidmətləri də təklif edirik. Biz, sizə insan sağlamlağına heç bir zərəri olmayan sadəcə bir məhsulla Huwa-San TR50 ilə, pandemiyaların qarşısının alınmasını, xəstəxanalarda istifadə olunan bütün alət, cihazlar və mühitlərin dezinfeksiyasını, otel, restoran, hamam və hovuzların, gida və catering sektorunda meyvə,tərəvəz və alətlərin təmizliyini, heyvandarlıq və quşçuluqda sağım aparatlarının, qidalanma və sulama aparatlarından yayılan xəstəliklərin yayılmasının, zərərlilərdən, yosunlardan 100% təmizlənməsini və qorunmasını təklif edirik.
           </p>
         </div>
       </div>
     </div> 
   </div>
   </>
  )
}

export default WhoUs
