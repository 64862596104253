import React, { useEffect, useState } from 'react'
import ProductCard from './ProductCard';
import { blog, blog1, blog2, blog3, leaf, leaf2, product1, product2, product3, product4 } from '../../../assets/images';
import axios from 'axios';

const Blogs = () => {

  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.sarkimya.az/sarkimya/blogs.php`,
        { withCredentials: false }
      );
      const filteredProducts = response.data.data;
      setBlogs(filteredProducts);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };


  if (isLoading) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  }

  return (
  <div className='bg-[#F1F1F1] mx-auto pb-10 pt-10 md:pb-20 md:pt-20'>
        <div className='mb-[10px] text-center'>
     <p className='text-[#6F9B0B] text-[22px] md:text-[32px] font-medium'>Blog</p>
     </div>
      <div className="mx-auto py-5 md:py-10 max-w-containerSm md:max-w-[1260px]">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
      {blogs.map((product) => (
        <a href={`/blog-detail/${product.id}`}>
          <ProductCard
          key={product.id}
          title={product.title}
          text={product.text}
          image1={product.image1}
        />
        </a>
      ))}
    </div>
  </div>
  <a href='/blogs'> <p className='text-[#6F9B0B] mt-3 text-center'>Daha çox <i class="fa-solid fa-arrow-right"></i></p></a>
  </div>
  )
}

export default Blogs