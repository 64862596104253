import React from 'react'
import { discount1, discount2, discount3, hero1, hero2, heroBg } from '../../../assets/images';


const Hero = () => {
  return (
   <div style={{ backgroundImage: `url(${heroBg})` }} >
     <div className="md:max-w-[1260px] max-w-containerSm mx-auto md:flex justify-between md:h-[633px] pt-[40px] md:pt-0 ">
      <div className='md:w-1/2 flex flex-col justify-center'> 
      <h1 className='md:text-[70px] text-[24px] font-extrabold text-[#6F9B0B] tracking-wider text-center md:text-left'>HUWASAN</h1> 
        <p className='md:text-4xl text-[20px] leading-10 text-center md:text-left'>Təmiz bir gələcək üçün etibarlı dostunuz</p>
        <a className='mx-auto md:mx-0' href='/about'>
        <button className="w-[148px] h-10 p-2.5 bg-[#6f9b0b] rounded-xl justify-center items-center gap-2.5 inline-flex mt-3 ">
    <div className="text-white text-base">Daha çox</div>
</button>
        </a>
      </div>
      <div className='md:w-2/5 flex flex-col justify-end mb-10 mt-4 md:mt-0'><img src={hero2}></img></div>
  </div>
   </div>
  )
} 

export default Hero 