import React from 'react'
import { blogLeft, blogRightBottom, blogRightTop, productBg, servicesBg, whyus } from '../../assets/images';

const Services = () => {
  return (
   <>
   <div style={{height: window.innerWidth < 640 ? '147px' : '310px', backgroundImage: `url(${servicesBg})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent:'center', alignItems:'end' }}>
     <div className='mb-[10px] md:mb-[40px] text-center'>
       <p className='text-xl italic text-[#BCBCBC]'>Xidmətlər</p>
       <p className='text-[#6F9B0B] text-[22px] md:text-[32px] font-medium'>İstifadə sahəsi</p>
     </div>
   </div>
    <div>
    <div className=' md:h-[416px] mx-auto md:flex justify-between mt-[40px] md:mt-[100px] mb-[40px] md:mb-[160px] max-w-containerSm md:max-w-[1260px]'>
    <div>
   <img className='md:w-[524px]' src={blogLeft}></img>
    </div>
    <div className='flex justify-center items-center md:w-[696px] text-lg mt-[40px] md:mt-0'>
      <p>Parniklərdə, ştilxanalarda, quşçuluq və heyvandarlıqda, açıq və qapalı hovuzlarda, xəstəxanalarda, hamamlarda, suvarma sistemlərində, bütün növ məkanların dezinfeksiyasında bizim məhsulumuzu istifadə edə bilərsiniz. . Hər damlasında möcüzə var. Buna zəmanət veririk.</p>
    </div>
    </div>
  </div></>
  )
}

export default Services