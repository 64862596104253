import React from 'react'
import { certificate1, certificate2, certificate3, certificate4, certificate5, certificate6, check, huwasan, productBg, productExtra, productsMain, virus1, virus2, virus3, virus4, virus5, whyus } from '../../assets/images';

const Products = () => {
  return (
   <>
   <div style={{height: window.innerWidth < 640 ? '120px' : '230px', backgroundImage: `url(${productBg})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent:'center', alignItems:'end' }}>
     <div className='mb-[16px] md:mb-[44px] text-center'>
       <p className='text-[#FFFFFF] text-[32px] md:text-[40px] font-medium'>Məhsullarımız</p>
     </div>
   </div>
    <div> 
    <div className='md:max-w-[1260px] max-w-containerSm mx-auto'>
    <div className='mt-[40px] md:mt-[100px] flex flex-col-reverse md:flex-row justify-between'>
  <div className='md:w-[30%] mt-[16px] md:mt-0'>
    <img className='h-[292px] w-[304px]' src={productsMain}></img>
  </div>
  <div className='md:w-[66%] flex justify-center items-center'>
    <p className='text-base'>
    Huwa-San yeni nəsil ekoloji dezinfeksiyaedici və bitki bəsləyicidir. Xüsusi formulası kolloid gümüş hissəcikləri ilə stabilləşdirilmiş hidrogen peroksiddir. Koloidal gümüş və hidrogen peroksid arasında sinergetik qarşılıqlı əlaqə ilə Huwa-San çox təsirlidir. Aşağı dozalarda güclü təsir göstərir. Huwasan effektiv, ekoloji cəhətdən təmiz və qənaətcil dezinfeksiyaedicidir. Huwasan güclü, təsirli, ekoloji cəhətdən təmiz dezinfeksiyaedici olduğundan, müəssisələrdə bir çox sahədə uğurla tətbiq olunur.
   <p className='text-[#6F9B0B]'>100% ekoloji</p>
   <p className='text-[#6F9B0B]'>100% effektiv</p>
   <p className='text-[#6F9B0B]'>100% təbii parçalana bilən</p>
    </p>
  </div>
    </div>
    <div className='md:mt-[100px] mt-[40px] mb-[40px] md:mb-[100px] mx-auto'>
      <img className='md:h-[478px] mx-auto' src={productExtra}>
      </img>
    </div>

    <div className='md:mt-[100px] md:mb-[120px] pb-10'>
    <p className='text-[#6F9B0B] text-[22px] md:text-[32px] font-medium text-center mb-[20px] md:mb-[30px] md:pt-[50px]'>Üstünlüklərimiz</p>
    <div className='md:max-w-[1260px] max-w-containerSm mx-auto'>

    <div>
  <div className='gap-5'>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Huwasan TR 50 bir məhsulla döşəmə, səth, alət və havanın dezinfeksiyasını təmin edir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Huwasan güclü və ekoloji dezinfeksiyaedicidir. İstifadəçi dostudur.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Bitkilərin böyüməsi üçün lazım olan oksigeni bol miqdarda birbaşa kökdən verir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Bitkinin lazımi qida maddələrini ala bilməsi üçün üzvi maddələri parçalayır.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Huwa-San ştilləri və kökləri patogenlərdən qoruyur.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Aktiv oksigenə əsaslanır və tərkibində kolloid gümüşü ilə stabilləşdirilmiş Hidrogen Peroksid var.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Yüksək səviyyədə dezinfeksiyanı təmin edir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Geniş fəaliyyət spektrinə malikdir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Bakterisid, virususidal, sporosid, funqisid və amibisid təsir göstərir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Stabildir və depo effektinə malikdir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>95 dərəcə istiliyə qədər qərarlıdır.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Rəngsiz və qoxusuzdur. Heç bir narahatedici rəng, qoxu və ya dad yaratmır.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>99,9% bioloji parçalana bilir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Reaksiyadan sonra suya və oksigenə çevrilir. Zərərli heç bir qalıq buraxmır.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Zəhərli deyil.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Xlor və spirtdən azaddır.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>İstifadədən sonra yaxalamaq lazım deyil.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Xlorun əksinə kanserogen və ya mutagen deyildir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Ətrafda heç bir qalıq qoymur.

</p>
  </div> <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Aşağı dozalarda yüksək səviyyədə təsir göstərir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Müvafiq dozalarda istifadə edən personala və materiallara heç bir mənfi təsiri yoxdur.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Tətbiq dozalarında aşındırıcı deyil.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Təbiət və insanlar üçün faydalı olan ekoloji dezinfeksiyaedicidir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Güclü, effektiv və təhlükəsiz dezinfeksiyaedicidir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Müxtəlif məhsullar üçün əlavə xərclərinizi aradan qaldırır.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Konsentratlaşdırılmış dezinfeksiyaedicidir. Ekonomikdir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Test çubuqları (ölçüm çubuqları) ilə dərhal ölçülə bilər.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Quş qripinə (Avian influenza) qarşı təsirlidir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>ISO 9001:2000 və ISO 14001:2004 keyfiyyət idarəetmə sistemi şərtləri altında Belçikada istehsal olunur.</p>
  </div>

  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>35 ölkədə lisenziyaya malikdir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Huwa-San və Huwa-San texnologiyaları beynəlxalq səviyyədə etibarlı keyfiyyət sistemləri sertifikatına malikdir.</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Huwa-San DEFRA sertifikatlarına sahibdir. (DEFRA- Ətraf Mühit, Qida və Kənd İşləri Departamenti, Böyük Britaniya Ətraf Mühit,Ərzaq və Kənd Təsərrüfatı Nazirliyi)</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>CE sertifikatına malikdir. (CE sertifikatı Avropa İttifaqı və Avropa İqtisadi Bölgəsində bazara çıxarılan məhsullar üçün məcburi uyğunluq sertifikatıdır (nişanıdır). CE (sertifikatı) işarəsi məhsulların insan həyatına və əmlakına, bitki və heyvan varlığına və ətraf mühitə zərər verməyəcəyini göstərir.)</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Huwa-San NSF sertifikatına malikdir. (NSF Sertifikatı qida və su məhsullarında gigiyenik şərtlərin yerinə yetirildiyini göstərən və demək olar ki, bütün dünyada etibarlı olan sertifikatdır. 1944-cü ildə qurulan NSF (Milli Sanitariya/Gigiyena Fondu) qlobal miqyasda fəaliyyət göstərir və onun ən mühüm məqsədi ictimai sağlamlığı qorumaqdır.)</p>
  </div>
  <div className='mt-2 mb-2 mr-3 ml-3 flex'>
<img className='h-6 w-6' src={check}></img>
<p className='text-black text-base font-normal ml-2'>Ekoloji əkinçilik üçün uyğunluğu ECOCERT sertifikatı ilə təsdiq edilmişdir. (ECOCERT 1991-ci ildə Fransada yaradılmış orqanik sertifikatlaşdırma təşkilatıdır. Əsası Avropada olsa da, 80-dən çox ölkədə yoxlamalar aparır və onu dünyanın ən böyük orqanik sertifikatlaşdırma təşkilatlarından birinə çevirir. Onların əsas prinsipi “ekoloji cəhətdən təmiz” məhsul uyğunluğunu təmin etməkdir.)</p>
  </div>
 

  </div>
</div>

    </div>
    </div>

    <div className='md:mt-[124px] md:mb-[100px] mt-[40px] mb-[40px]'>
      <p className='text-[#6F9B0B] text-[22px] md:text-[32px] font-medium text-center mb-[30px]'>Sertifikatlarımız</p>
      <div className='md:flex justify-between gap-10'>
   <img className='h-[68px] w-52 mx-auto'  src={certificate1}></img>
   <img className='w-[118px] h-[87px] mx-auto' src={certificate2}></img>
   <img className='h-[101px] w-[168px] mx-auto'  src={certificate3}></img>
   <img className='h-[68px] w-[196px] mx-auto' src={certificate4}></img>
   <img className='w-[108px] h-[126px] mx-auto' src={certificate5}></img>
   <img className='w-28 h-[138px] mx-auto' src={certificate6}></img>
      </div>
    </div>
    </div>

  </div></>
  )
}

export default Products 