import React, { useEffect, useRef, useState } from 'react'
import { blog1, blog2, blog3, blogBg, huwasan, leaf2, whyus } from '../../assets/images'
import { useParams } from 'react-router-dom';
import axios from 'axios';


const BlogDetail = () => {

  const [blogs, setBlogs] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const swiperRef = useRef(null); // Swiper referansı
  const [activeIndex, setActiveIndex] = useState(0); // Aktif slide index

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.sarkimya.az/sarkimya/blogs.php`,
        { withCredentials: false }
      );
      const filteredProducts = response.data.data.filter(blog => blog.id == id);
      setBlogs(filteredProducts[0]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return ( 
   <>
        <div style={{height: window.innerWidth < 640 ? '120px' : '230px', backgroundImage: `url(${blogBg})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent:'center', alignItems:'end' }}>
     <div className='mb-[10px] md:mb-[44px] text-center'>
       <p className='text-[#6F9B0B] text-[32px] md:text-[40px] font-medium'>Bloq</p>
     </div>
   </div>
       <div className='md:max-w-[1260px] max-w-containerSm mx-auto'>
   <div className='mt-[100px] mb-[100px] md:flex justify-between'>
  <div className='md:w-[30%]'>
    <img src={`https://www.sarkimya.az/sarkimya/${blogs.image1}`}></img>
  </div>
  <div className='md:w-[66%]'>
    <p className='text-[28px] font-medium mb-5'>
    {blogs.title}
    </p>
    <p className='text-base text-[#a7a7a7]'>
    {blogs.text}
    </p>
  </div>
    </div>
    </div>
     
    </>
  )
}

export default BlogDetail