import React from 'react';
import { whyus } from '../../../assets/images';

const WhyUs = () => {

  return (
    <div className=''> 
    <div className='md:max-w-[1260px] max-w-containerSm mx-auto'>
      <div className='md:mt-[100px] md:mb-[100px] mt-[40px] mb-[40px]'>
      <p className='text-xl italic text-[#BCBCBC] text-center'>Biz kimik?</p>
      <p className='text-[#6F9B0B] text-[22px] md:text-[32px] font-medium text-center mb-[40px]'>S.A.R Kimya</p>
       <div className='md:flex justify-between'>
       <div className='md:w-1/3'>
          <img className='h-[436px]' src={whyus} alt="Why Us"></img>
        </div>
        <div className='md:w-3/5 flex flex-col justify-center mt-4 md:mt-0 '> 
          <p>
            Biz S.A.R Kimya olaraq yaşadığımız dünyanın təmiz, yediyimiz qidaların sağlıqlı və toxunduğumuz ətraf mühitin xəstəliksiz olması şüarı ilə yola çıxmış, işini sevən, məsuliyyətini dərk edən bir şirkətik.. S.A.R Kimya olaraq istixanalarda modern əkinçiliyin tələblərinə uyğun xidmət göstərir, bitki və məhusllarınızı xəstəliklərdən, zərərvericilərdən qoruyan, bitkilərin kök inkişafını dəstəkləyən və uzun müddət keyfiyyətini saxlayan, Belçikada, xüsusi mütəxəssis heyyəti tərəfindən istehsal edilmiş Huwa-San TR50-nin satışını həyata keçirir, bununla yanaşı sizə, 10 ildən çox təcrübəsi olan əməkdaşlarımızla agronom xidmətləri də təklif edirik. 
          </p>
         <a href='/who-us'> <p className='text-[#6F9B0B] mt-3'>Ətraflı bax <i class="fa-solid fa-arrow-right"></i></p></a>
        </div>
       </div>
      </div>
    </div> 
  </div>
  );
};

export default WhyUs;
