import React, { useEffect, useState } from 'react'
import { blog1, blog2, blog3, blogBg, leaf2, whyus } from '../../assets/images'
import ProductCard from './ProductCard';
import PaginatedItems from './PaginatedItems'
import axios from 'axios';

const Blog = () => {

  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.sarkimya.az/sarkimya/blogs.php`,
        { withCredentials: false }
      );
      const filteredProducts = response.data.data;
      setBlogs(filteredProducts);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };


  if (isLoading) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  }

  return (
   <>
     <div style={{height: window.innerWidth < 640 ? '120px' : '230px', backgroundImage: `url(${blogBg})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent:'center', alignItems:'end' }}>
     <div className='mb-[10px] md:mb-[44px] text-center'>
       <p className='text-[#6F9B0B] text-[32px] md:text-[40px] font-medium'>Bloq</p>
     </div>
   </div>
   <div className='bg-[#F1F1F1] mx-auto pb-20'>
      <div className="mx-auto py-10 max-w-containerSm md:max-w-[1260px]">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
      {blogs.map((product) => (
        <a href={`/blog-detail/${product.id}`}>
          <ProductCard
          key={product.id}
          title={product.title}
          text={product.text}
          image1={product.image1}
        />
        </a>
      ))}
    </div>
  </div>
  </div>
     
    </>
  )
}

export default Blog