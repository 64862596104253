import React, { useState } from 'react';
import { logo } from '../../assets/images';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const location = useLocation(); // Get current route

  // Function to check if the current path is active
  const isActiveLink = (path) => location.pathname === path;

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

  return (
    <>
      <div className='bg-[#E7E7E7] h-[80px] pt-5'>
        <div className='md:max-w-container max-w-containerSm mx-auto h-[45px] flex justify-between'>
          <div>
            <a href='/'><img alt='logo' height={76} width={167} src={logo}></img></a>
          </div>
          <div className='hidden md:flex justify-center items-center'>
            <nav>
              <ul className="flex space-x-8">
                <a href="/">
                  <li
                    className={isActiveLink('/') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Ana səhifə
                  </li>
                </a>
                <a href="/who-us">
                  <li
                    className={isActiveLink('/who-us') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Biz kimik
                  </li>
                </a>
                <a href="/products">
                  <li
                    className={isActiveLink('/products') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Məhsullar
                  </li>
                </a>
                <a href="/services">
                  <li
                    className={isActiveLink('/services') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Xidmətlər
                  </li>
                </a>
                <a href="/blogs">
                  <li
                    className={isActiveLink('/blogs') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Bloq
                  </li>
                </a>
                <a href="/contact">
                  <li
                    className={isActiveLink('/contact') ? 'text-[#6f9b0b] underline underline-offset-4' : ''}
                  >
                    Əlaqə
                  </li>
                </a>
              </ul>
            </nav>
          </div>
          <button className="md:hidden focus:outline-none" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[24px]"></i>
        {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>
                 <div className="flex">

          <button className="absolute top-5 right-5 p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[32px]"></i>
          </button>
          
                 </div>
          <div className="flex flex-col items-start p-8 w-full ">
            <div className="items-center justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen">
            <div className="gap-4 max-w-containerSm flex flex-col"> 
       <a href='/'> <img src={logo} alt="Logo" className="w-[120px]" /></a>
       <a className={`text-base text-left font-normal ${isActiveLink('/') ? 'text-[#0D4B2C] ' : 'text-[black]'}`} href="/"> Ana səhifə</a>
              <a href="/who-us" className={`text-base text-left font-normal ${isActiveLink('/products') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>Biz kimik</a>
              <a href="/products" className={`text-base text-left font-normal ${isActiveLink('/about') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>Məhsullar</a>
              <a href="/services" className={`text-base text-left font-normal ${isActiveLink('/services') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>Xidmətlər</a>
              <a href="/blogs" className={`text-base text-left  font-normal ${isActiveLink('/blogs') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>Bloq</a>
              <a href="/contact" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>Əlaqə</a>

    </div>

          </div>
          </div>
        </div>
      )} 
      </button>
        </div>
      </div>
    </>
  );
};

export default Header;






























